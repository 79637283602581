import React, { Suspense, useEffect, useState } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import { getNotificationPermission, messaging } from './notification/firebase'
import { onMessage } from 'firebase/messaging'
import toast from 'react-hot-toast'
import { Box, Paper, Typography } from '@mui/material'
import { useAuth } from './hooks/useAuth'
import BellOutline from 'mdi-material-ui/BellOutline'


//website 
const CareerCategoriesPage = React.lazy(() => import('./pages/website-content/career-section/career-categories'))
const CareerPage = React.lazy(() => import('./pages/website-content/career-section'))
const FaqsPage = React.lazy(() => import('./pages/website-content/faqs'))
const TermsandConditionPage = React.lazy(() => import('./pages/website-content/termsandcondition'))
const TestimonialsPage = React.lazy(() => import('./pages/website-content/testimonials'))
const ContactUsPage = React.lazy(() => import('./pages/website-content/contact-us'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/website-content/privacy-policy'))
const BlogPage = React.lazy(() => import('./pages/website-content/blog'))

//admin
const HomePage = React.lazy(() => import('./pages/home'))
const UsersPage = React.lazy(() => import('./pages/users'))
const DashboardPage = React.lazy(() => import('./pages/dashboard'))
const LoginPage = React.lazy(() => import('./pages/login'))
const ForgotPassword = React.lazy(() => import('./pages/login/forgotpassword'))
const RugsPage = React.lazy(() => import('./pages/rugs'))
const RugDetailPage = React.lazy(() => import('./pages/rugs/rugDetailPage'))
const AddRugs = React.lazy(() => import('./pages/rugs/addRugs'))
const CollectionPage = React.lazy(() => import('./pages/collection'))
const NotificationPage = React.lazy(() => import('./pages/notification'))
const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj
  const { user, getUnReadNotificationCount } = useAuth()
  // console.log('DEBUG user', user)

  const [isTokenFound, setTokenFound] = useState(false)

  useEffect(() => {
    if (user) {
      getNotificationPermission(setTokenFound);
      onMessage(messaging, (payload) => {
        getUnReadNotificationCount();
        // console.log("Message received", payload);
        toast(
          (t) => (
            <Box display={"flex"} alignItems={"flex-start"} gap={3}>
              {/* <Avatar
                src={MessageImage}
                alt="NotificationIcon"
                variant="square"
              /> */}
              <BellOutline />
              <Box>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "25px" }}
                >
                  {payload.notification.title}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "15px" }}
                >
                  {payload.notification.body}
                </Typography>
              </Box>
            </Box>
          ),
          {
            style: { border: "1px solid #3B8D5C", borderRadius: "12px" },
            position: "top-right",
            duration: 3000,
          }
        );
        // alert(payload.notification.title);
        // alert(payload.notification.body);
      });
    }
  }, [user]);
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route element={<BlankLayout><Outlet /></BlankLayout>}>
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route element={<AuthLayout><Outlet /></AuthLayout>}>
              <Route element={<Guard guestGuard><Outlet /></Guard>}>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
            </Route>
          </Route>

          <Route element={<UserLayout><Outlet /></UserLayout>}>
            <Route element={<Guard authGuard><Outlet /></Guard>}>
              <Route path='' element={<HomePage />} />
              <Route path='/dashboard' element={<DashboardPage />} />
              <Route path='/rugs' element={<RugsPage />} />
              <Route path='/rugs/:id' element={<RugDetailPage />} />
              <Route path='/rugs/add' element={<AddRugs />} />
              <Route path='/rugs/edit/:id' element={<AddRugs />} />
              <Route path='/users' element={<UsersPage />} />
              <Route path='/collection' element={<CollectionPage />} />
              <Route path='/notifications' element={<NotificationPage />} />

              {/* website */}
              <Route path='/career-section' element={<CareerPage />} />
              <Route path='/career-categories' element={<CareerCategoriesPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
              <Route path='/terms-conditions' element={<TermsandConditionPage />} />
              <Route path='/testimonials' element={<TestimonialsPage />} />
              <Route path='/contact-us' element={<ContactUsPage />} />
              <Route path='/faqs' element={<FaqsPage />} />
              <Route path='/blog' element={<BlogPage />} />

            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />

        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
