//export const API_BASE_URL = "http://192.168.29.55:3000"
export const API_BASE_URL = "https://api.dev.rugauction.com"

export const MEDIA_URL = "https://d1ysfw85jlu7hq.cloudfront.net"
export const ApiEndPoints = {
    AUTH: {
        login: `${API_BASE_URL}/api/admin/v1/auth/login`,
        me: `${API_BASE_URL}/api/admin/v1/auth/me`,
        forgot: `${API_BASE_URL}/api/v1/admin/auth/forgot/password`,
        verifotp: `${API_BASE_URL}/api/v1/admin/auth/verify/otp`,
        reset: `${API_BASE_URL}/api/v1/admin/auth/reset/password`
    },
    DASHBOARD: {
        count: `${API_BASE_URL}/api/admin/v1/dashboard/stats`
    },
    USERS: {
        list: `${API_BASE_URL}/api/admin/v1/user`,
        create: `${API_BASE_URL}/api/admin/v1/user/add`,
        edit: (id) => `${API_BASE_URL}/api/admin/v1/user/add/${id}`,
        delete: (id) => `${API_BASE_URL}/api/admin/v1/user/add/${id}`,
    },
    ADD_RUGS: {
        list: `${API_BASE_URL}/api/admin/v1/create-auction/list`,
        create: `${API_BASE_URL}/api/admin/v1/create-auction`,
        listbyid: (id) => `${API_BASE_URL}/api/admin/v1/create-auction/${id}`,
        edit : (id) => `${API_BASE_URL}/api/admin/v1/create-auction/${id}`,
        offer: (id) => `${API_BASE_URL}/api/admin/v1/create-auction/offer/list/${id}`,
    },
    PRIVACY_POLICY: {
        list: `${API_BASE_URL}/api/admin/v1/privacypolicy/list`,
        edit: (id) => `${API_BASE_URL}/api/admin/v1/privacypolicy/update/${id}`
    },
    Terms_AND_CONDITIONS: {
        list: `${API_BASE_URL}/api/admin/v1/terms/list`,
        edit: (id) =>`${API_BASE_URL}/api/admin/v1/terms/update/${id}`
    },
    ContactUs: {
        list: `${API_BASE_URL}/api/admin/v1/contactus`,
    },
    FAQ: {
        list: `${API_BASE_URL}/api/admin/v1/faq/list`,
        create: `${API_BASE_URL}/api/admin/v1/faq`,
        edit: (id) => `${API_BASE_URL}/api/admin/v1/faq/update/${id}`,
        delete: (id) => `${API_BASE_URL}/api/admin/v1/faq/remove/${id}`
    },
    BLOG: {
        list: `${API_BASE_URL}/api/admin/v1/blog/list`,
        create: `${API_BASE_URL}/api/admin/v1/blog`,
        edit: (id) => `${API_BASE_URL}/api/admin/v1/blog/update/${id}`,
        delete: (id) => `${API_BASE_URL}/api/admin/v1/blog/remove/${id}`
    },
    COLLECTION: {
        list: `${API_BASE_URL}/api/admin/v1/collection/list`,
        create: `${API_BASE_URL}/api/admin/v1/collection`,
        edit: (id) => `${API_BASE_URL}/api/admin/v1/collection/update/${id}`,
        status: (id) => `${API_BASE_URL}/api/admin/v1/collection/status/${id}`
    },
    NOTIFICATIONS: {
        get_notifications: `${API_BASE_URL}/api/admin/v1/notification`,
        sent_fcm_token: `${API_BASE_URL}/api/admin/v1/notification/token`,
        get_unread_notifications_count: `${API_BASE_URL}/api/admin/v1/notification/unread/count`,
        read_notifications: `${API_BASE_URL}/api/admin/v1notification/read`
      },
}
